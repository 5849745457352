import { Method } from '../../common/menu.types.mjs';
const defaultHomeNavItem = {
  id: "home",
  title: "Home",
  pathname: "/app/home"
};
const fallbackItems = {
  helpLabel: "Help",
  menuLabel: "Menu",
  navItems: [defaultHomeNavItem],
  profileItem: {
    title: "Account",
    userName: "",
    companyName: "",
    menuItems: [{
      id: "userSettings",
      pathname: "/my/user-settings",
      title: "User settings"
    }, {
      id: "privacy",
      href: "//www.cultureamp.com/privacy-policy",
      title: "Privacy"
    }, {
      id: "terms",
      href: "//www.cultureamp.com/terms",
      title: "Terms"
    }, {
      id: "signOut",
      pathname: "/session/sign_out",
      title: "Sign out",
      method: Method.DELETE,
      onSuccessRedirect: "//www.cultureamp.com"
    }]
  }
};
const devFallbackItems = {
  helpLabel: "Help",
  menuLabel: "Menu",
  navItems: [{
    id: "home",
    title: "Home",
    pathname: "/app/home"
  }, {
    id: "feedback",
    title: "Feedback",
    menuItems: [{
      id: "surveysPrograms",
      pathname: "/my/surveys_new",
      title: "Surveys"
    }, {
      id: "anytimeFeedback",
      pathname: "/app/navigation/feedback",
      title: "Anytime feedback"
    }, {
      id: "shoutouts",
      pathname: "/app/v2-recognize/you",
      title: "Shoutouts"
    }]
  }, {
    id: "oneOnOnes",
    title: "1-on-1s",
    pathname: "/app/conversations"
  }, {
    id: "goals",
    title: "Goals",
    menuItems: [{
      id: "goals",
      pathname: "/performance/new_goals",
      title: "Goals"
    }, {
      id: "actionPlans",
      pathname: "/my/action_plans",
      title: "Action plans"
    }, {
      groupTitle: "Admin",
      id: "admin",
      menuItems: [{
        id: "adminAllGoals",
        pathname: "/performance/admin/goals/stats",
        title: "Usage"
      }, {
        id: "adminGoalsOverview",
        pathname: "/app/goals/reporting",
        title: "Reporting"
      }]
    }]
  }, {
    id: "perfomanceMenu",
    menuItems: [{
      id: "performanceLandingPage",
      pathname: "/performance/evaluation_cycles/summary",
      title: "Reviews"
    },
    // * Temporarily removing this. It'll soon be deprecated, but we also need to consider
    // a reliable way of handling cases where we have aggregate IDs. This will be explored in a new ticket.
    // {
    //   id: "selfReflectionsLandingPage",
    //   pathname: "/app/cycles-view/{aggregateId}",
    //   title: "Self-reflections",
    // },
    {
      groupTitle: "Admin",
      id: "admin",
      menuItems: [{
        id: "adminPerfReviewCycles",
        pathname: "/performance/admin/evaluation_cycles",
        title: "Performance review (custom term) cycles"
      }, {
        id: "adminCalibrations",
        pathname: "/performance/admin/calibrations/views",
        title: "Calibrations"
      },
      // * Temporarily removing this. It'll soon be deprecated, but we also need to consider
      // a reliable way of handling cases where we have aggregate IDs. This will be explored in a new ticket.
      // {
      //   id: "adminSelfReflectionCycles",
      //   pathname: "/performance/admin/self_reflection",
      //   title: "Self-reflection cycles",
      // },
      {
        id: "reviewsAdminInsights",
        pathname: "/app/performance-insights",
        title: "Insights"
      }]
    }],
    title: "Performance"
  }, {
    id: "development",
    title: "Development",
    menuItems: [{
      id: "developmentPlans",
      pathname: "/app/develop",
      title: "Development plans"
    }, {
      id: "skillsCoach",
      pathname: "/app/skills-coach",
      title: "Skills coach"
    }, {
      id: "developmentResources",
      pathname: "/performance/development",
      title: "Resources"
    }, {
      id: "careerPathways",
      pathname: "/app/career-pathways",
      title: "Career paths"
    }]
  }, {
    id: "analytics",
    title: "Analytics",
    pathname: "/app/analytics"
  }],
  notificationsItem: {
    id: "notifications",
    pathname: "/performance/notifications",
    title: "Notifications",
    query: "redirect=false"
  },
  profileItem: {
    title: "Account",
    userName: "Localhost",
    companyName: "Development",
    isMasquerading: false,
    menuItems: [{
      id: "userSettings",
      pathname: "/my/user-settings",
      title: "User settings"
    }, {
      href: "//www.cultureamp.com/privacy-policy",
      id: "privacy",
      title: "Privacy"
    }, {
      href: "//www.cultureamp.com/terms",
      id: "terms",
      title: "Terms"
    }, {
      id: "signOut",
      method: Method.DELETE,
      pathname: "/session/sign_out",
      title: "Sign out"
    }]
  },
  settingsItem: {
    id: "settings",
    mobileTitle: "Account settings",
    pathname: "/my/account_admin",
    title: "Settings"
  },
  superuserItem: {
    menuItems: [{
      id: "globalAdmin",
      pathname: "/admin",
      title: "Global admin"
    }, {
      id: "masquerade",
      pathname: "/admin/masquerade/new",
      title: "Masquerade"
    }, {
      id: "accountSummary",
      pathname: "/admin/accounts_summary",
      title: "Account summary"
    }, {
      id: "backgroundJobs",
      pathname: "/background_jobs",
      title: "Background jobs"
    }, {
      id: "benchmarks",
      pathname: "/admin/benchmarks",
      title: "Benchmarks"
    }, {
      id: "bulkEditAccounts",
      pathname: "/bulk_account_update/new",
      title: "Bulk edit accounts"
    }, {
      id: "camperManagement",
      pathname: "/campers",
      title: "Camper management"
    }, {
      id: "commsTemplates",
      pathname: "/admin/survey_period_templates",
      title: "Comms templates"
    }, {
      id: "employeeSearch",
      pathname: "/admin/person",
      title: "Employee search"
    }, {
      id: "featureFlagsAndConfig",
      pathname: "/features",
      title: "Feature flags and config"
    }, {
      id: "launchPlans",
      pathname: "/launch_plans",
      title: "Launch plans"
    }, {
      id: "segmentHierarchies",
      pathname: "/admin/hierarchies",
      title: "Segment hierarchies"
    }],
    title: "Superuser"
  }
};
export { defaultHomeNavItem, devFallbackItems, fallbackItems };
