import { getPathnameOrHref } from './uiHelpers.mjs';
const httpRequest = async (link, data) => {
  if (!link.method) return;
  const options = {
    method: link.method.toUpperCase(),
    headers: {
      "Content-type": "application/json"
    },
    body: JSON.stringify(data)
  };
  const res = await fetch(getPathnameOrHref(link), options);
  if ((res.ok || res.redirected) && link.onSuccessRedirect && typeof window !== "undefined") {
    window.location.href = link.onSuccessRedirect;
  }
};
export { httpRequest };
