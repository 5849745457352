import * as amplitude from '@amplitude/analytics-browser';

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull unified-navigation'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 29
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/implementation/unified-navigation)
 */
const ApiKey = {
  default: "6cfe80b109d58db414efa2dbae828525"
};
/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
const DefaultConfiguration = {
  plan: {
    version: "29",
    branch: "main",
    source: "unified-navigation",
    versionId: "c4a680f9-de04-4c3c-8ddc-e483e6ae6cf5"
  },
  ...{
    ingestionMetadata: {
      sourceName: "browser-typescript-ampli",
      sourceVersion: "2.0.0"
    }
  }
};
class HelpToolInitiated {
  constructor() {
    this.event_type = "Help Tool Initiated";
  }
}
class NavigationBarClicked {
  constructor(event_properties) {
    this.event_properties = event_properties;
    this.event_type = "Navigation Bar Clicked";
    this.event_properties = event_properties;
  }
}
const getVoidPromiseResult = () => ({
  promise: Promise.resolve()
});
// prettier-ignore
class Ampli {
  constructor() {
    this.disabled = false;
  }
  get client() {
    this.isInitializedAndEnabled();
    return this.amplitude;
  }
  get isLoaded() {
    return this.amplitude != null;
  }
  isInitializedAndEnabled() {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }
  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options) {
    var _a;
    this.disabled = (_a = options.disabled) !== null && _a !== void 0 ? _a : false;
    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }
    let apiKey = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }
    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = options.client && 'configuration' in options.client ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, {
        ...DefaultConfiguration,
        ...configuration
      });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }
    return getVoidPromiseResult();
  }
  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(userId, properties, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }
    if (userId) {
      options = {
        ...options,
        user_id: userId
      };
    }
    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude.identify(amplitudeIdentify, options);
  }
  /**
   * Flush the event.
   */
  flush() {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }
    return this.amplitude.flush();
  }
  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event, options) {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }
    return this.amplitude.track(event, undefined, options);
  }
  /**
   * Help Tool Initiated
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Help%20Tool%20Initiated)
   *
   * User clicks on 'Help' to open the Intercom tool.
   *
   * Triggers:
   * 1. User clicks on "Help" in the navigation bar
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/TZwIiYFAruX3TTQ3b-jBsPoQDhMtzLqfk_0YC62DjWFZjqkHNjoxysssvk0iFeOaogRD2BcJVrsNKu9QDPK6yDYj250_04S7ukmk=w700)
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/dSIr4EgQ0f/trigger/HyiHhHdHi)
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * Owner: api
   *
   * @param options Amplitude event options.
   */
  helpToolInitiated(options) {
    return this.track(new HelpToolInitiated(), options);
  }
  /**
   * Navigation Bar Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/culture-amp/%F0%9F%8C%88%20Product%20-%20Production/events/main/latest/Navigation%20Bar%20Clicked)
   *
   * User clicks on the navigation bar to visit another page.
   *
   * Triggers:
   * 1. User clicks on an item in the navigation bar.
   *   _Triggered on all sources_
   *   ![](https://lh3.googleusercontent.com/RmYNvZm4UOF7MMHtYrzyGfPc6d8X0DD6DMNfXAA5iNd56VRr8wWHYUXjD7bl-tFkgVabv7755fOKYNGj3h2xcwcdKCVsvbqSveED1w=w700)
   *   [View trigger in Avo](https://www.avo.app/schemas/SyotC3NRBiaKYbijsQS9/events/rN608-ClY8/trigger/0G4w9yZDL)
   *
   * _This description was published from [Avo](https://avo.app/schemas/SyotC3NRBiaKYbijsQS9)_
   *
   * @param properties The event's properties (e.g. Menu header)
   * @param options Amplitude event options.
   */
  navigationBarClicked(properties, options) {
    return this.track(new NavigationBarClicked(properties), options);
  }
}
const ampli = new Ampli();
export { Ampli, ApiKey, DefaultConfiguration, HelpToolInitiated, NavigationBarClicked, ampli };
