import { jsx, jsxs } from 'react/jsx-runtime';
import { Menu, MenuList, MenuItem, Heading, Avatar } from '@kaizen/components';
import { CompanyLogo } from '../CompanyLogo/CompanyLogo.mjs';
import { ampli } from '../../ampli/index.mjs';
import { getPathnameOrHref, containsNumeral } from '../helpers/uiHelpers.mjs';
import { httpRequest } from '../helpers/httpRequestHelper.mjs';
import React from 'react';
import cx from 'classnames';
const ProfileMenuHeader = ({
  companyName,
  userName,
  companyAvatar
}) => jsxs("div", {
  className: "un-flex  un-rounded-t-[4px] un-p-[10px_12px] un-box-border un-m-[-4px_0_5px] un-border-b-1 un-border-purple-500/[0.1]",
  children: [jsxs("div", {
    className: "un-flex-col un-mr-auto un-overflow-hidden [&>*]:un-whitespace-nowrap [&>*]:un-overflow-hidden [&>*]:un-overflow-ellipsis",
    children: [jsx(Heading, {
      tag: "p",
      variant: "heading-6",
      color: "dark-reduced-opacity",
      children: jsx("span", {
        title: companyName,
        children: companyName
      })
    }), jsx(Heading, {
      tag: "p",
      variant: "heading-5",
      color: "dark",
      children: jsx("span", {
        title: userName,
        children: userName
      })
    })]
  }), jsx(CompanyLogo, {
    className: "un-flex-none un-m-[null_0_null_12px] rtl:un-m-[null_12px_null_0]",
    src: companyAvatar,
    companyName: companyName
  })]
});
const MemoisedAvatar = props => {
  let avatarSrc = React.useRef(props.userAvatar);
  if ((avatarSrc === null || avatarSrc === void 0 ? void 0 : avatarSrc.current) === undefined) {
    avatarSrc.current = props.userAvatar;
  }
  return jsx(Avatar, {
    disableInitials: containsNumeral(props.userName),
    fullName: props.userName,
    size: "medium",
    avatarSrc: avatarSrc.current,
    isCurrentUser: !props.isMasquerading
  });
};
const ProfileMenu = props => {
  return jsx(Menu, {
    dropdownWidth: "contain",
    button: jsx("button", {
      className: cx("un-border-0 un-bg-transparent un-relative hover:un-cursor-pointer ",
      // focus state
      "focus:un-outline-none focus-visible:after:un-content-[''] focus-visible:after:un-absolute focus-visible:after:un-bg-transparent focus-visible:after:focus-visible:un-rounded-[10px] focus-visible:after:focus-visible:un-border-[2px] focus-visible:after:un-border-solid focus-visible:after:un-top-[calc(-1*2px)] focus-visible:after:un-bottom-[calc(-1*2px)] focus-visible:after:un-left-[calc(-1*2px)] focus-visible:after:un-right-[calc(-1*2px)]", props.variant === "education" ? "focus-visible:after:un-border-blue-500" : "focus-visible:after:un-border-blue-200"),
      title: "profile menu",
      "aria-label": "profile menu",
      children: jsx(MemoisedAvatar, {
        userName: props.userName,
        userAvatar: props.userAvatar,
        isMasquerading: props.isMasquerading
      })
    }),
    align: "right",
    children: jsxs("div", {
      className: "un-min-w-[196px] un-max-w-[360px] un-flex un-flex-col",
      children: [(props.userName || props.companyName) && jsx(ProfileMenuHeader, {
        userName: props.userName,
        companyName: props.companyName,
        companyAvatar: props.companyAvatar
      }), jsx(MenuList, {
        children: props.menuItems.map(item => jsx(MenuItem, {
          label: item.title,
          href: getPathnameOrHref(item),
          onClick: event => {
            props.onLinkClick && props.onLinkClick(event);
            // Only button events support event.currentTarget.value, anchor element event can be ignored
            if ("value" in event.currentTarget) {
              event.currentTarget.value = getPathnameOrHref(item);
            }
            ampli.navigationBarClicked({
              "Menu item": item.id,
              "Menu header": "Profile"
            });
            if (item.method && !event.defaultPrevented) {
              event.preventDefault();
              httpRequest(item, {});
            }
          }
        }, item.id))
      })]
    })
  });
};
export { ProfileMenu as default };
