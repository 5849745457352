import { getComputedMarginEnd } from '../helpers/rtlHelpers.mjs';
import { useRef, useState, useCallback, useEffect, useLayoutEffect } from 'react';
import debounce from 'lodash.debounce';
import useResizeObserver from 'use-resize-observer/polyfilled';
function useResponsiveVariant(textDirection = "ltr", loading, ...orderedVariants) {
  const logoAndNavItemsContainerRef = useRef(null);
  const maxVariantIndex = orderedVariants.length - 1;
  // Always start with maximum variant
  const [responsiveVariantIndex, setResponsiveVariantIndex] = useState(maxVariantIndex);
  // Return next tier down, or 0
  const setNextLowestVariant = () => setResponsiveVariantIndex(currentVariant => Math.max(currentVariant - 1, 0));
  const calculateMargin = useCallback(() => {
    // measure new margin width
    const currentMarginWidth = getComputedMarginEnd(logoAndNavItemsContainerRef === null || logoAndNavItemsContainerRef === void 0 ? void 0 : logoAndNavItemsContainerRef.current, textDirection);
    // if margin width has become zero
    if (currentMarginWidth === 0) {
      // change down variant
      setNextLowestVariant();
    } else {
      // Reset to max variant
      setResponsiveVariantIndex(maxVariantIndex);
    }
  }, [textDirection, maxVariantIndex]);
  const navbarRef = useRef(null);
  useResizeObserver({
    ref: navbarRef,
    // on navbar element change
    onResize: debounce(calculateMargin,
    // Wait for user to stop resizing using debounce
    200, {
      leading: false
    })
  });
  useEffect(() => {
    // Recalculate if the loading state changes
    calculateMargin();
  }, [loading, calculateMargin]);
  // if responsive variant changes
  const useIsomorphicLayoutEffect = typeof window !== "undefined" ? useLayoutEffect : useEffect;
  useIsomorphicLayoutEffect(() => {
    // get new margin width
    const currentMarginWidth = getComputedMarginEnd(logoAndNavItemsContainerRef === null || logoAndNavItemsContainerRef === void 0 ? void 0 : logoAndNavItemsContainerRef.current, textDirection);
    // if margin width is still zero
    if (currentMarginWidth === 0) {
      // change down variant again
      setNextLowestVariant();
    }
  }, [responsiveVariantIndex, textDirection]);
  return {
    logoAndNavItemsContainerRef,
    navbarRef,
    // Helper functions that check variable name against current variant index
    isAboveOrEqualTo: variantName => orderedVariants.indexOf(variantName) <= responsiveVariantIndex,
    isAbove: variantName => orderedVariants.indexOf(variantName) < responsiveVariantIndex,
    isBelowOrEqualTo: variantName => orderedVariants.indexOf(variantName) >= responsiveVariantIndex,
    currentResponsiveVariant: orderedVariants[responsiveVariantIndex]
  };
}
export { useResponsiveVariant as default };
