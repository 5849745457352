import { jsx } from 'react/jsx-runtime';
import { Menu, Avatar, MenuList, MenuItem } from '@kaizen/components';
import { getPathnameOrHref } from '../helpers/uiHelpers.mjs';
import cx from 'classnames';
const SuperuserMenu = props => {
  return jsx(Menu, {
    button: jsx("button", {
      className: cx("un-border-none un-bg-transparent un-relative",
      // hover
      "hover:un-cursor-pointer",
      // focus
      "focus:un-outline-none focus-visible:after:un-content-[''] focus-visible:after:un-absolute focus-visible:after:un-bg-transparent focus-visible:after:un-rounded-focus-ring focus-visible:after:un-border focus-visible:after:un-border-solid focus-visible:after:un-inset-[calc(-1*2px)]", props.variant === "education" ? "focus-visible:after:un-border-blue-500" : "focus-visible:after:un-border-blue-300"),
      title: "Profile",
      type: "button",
      children: jsx(Avatar, {
        fullName: "S",
        size: "medium",
        isCurrentUser: false
      })
    }),
    align: "right",
    children: jsx(MenuList, {
      children: props.menuItems.map(item => jsx(MenuItem, {
        label: item.title,
        onClick: props.onLinkClick,
        href: getPathnameOrHref(item)
      }, item.id))
    })
  });
};
export { SuperuserMenu as default };
