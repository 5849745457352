import { jsx } from 'react/jsx-runtime';
import { getClientRequestUrl, forbiddenEvent, responseBody, responseError, clientFetch } from '@cultureamp/frontend-apis';
import { useQueryClient, useQuery as useQuery$1, QueryClient, QueryClientProvider as QueryClientProvider$1 } from '@tanstack/react-query';
import { determineEnvironment } from '@cultureamp/frontend-env';
import { getLoginUrl } from '@cultureamp/redirect-to-login';
import React, { useEffect } from 'react';
const createKey = (url, options) => {
  return [url, {
    query: options === null || options === void 0 ? void 0 : options.query,
    params: options === null || options === void 0 ? void 0 : options.params
  }];
};
const RETRY_ENVIRONMENTS = ["production", "staging", "development"];
const shouldRetry = (retryCount, error) => {
  // In production like envs we want to attempt recovery, elsewhere fail fast
  if (!RETRY_ENVIRONMENTS.includes(determineEnvironment().realm)) return false;
  const statusCode = error.status;
  return statusCode >= 400 && statusCode < 500 ? false : retryCount < 3;
};
const useHead = children => {};
// flag if we are in initial render
// on server it's always true
// on client it's true before first paint and false after
let isInitialRender = true;
// preload will be rendered on server and during first paint on client
// then it'll stay in DOM until the component is rerendered
// note that removing script from dom doesn't cancel the request, it'll be still available and used
const PRELOAD_ENVIRONMENTS = ["production", "staging", "development"];
const useQueryPreload = (url, options) => {
  const enabled = !(options === null || options === void 0 ? void 0 : options.disableQueryPreload) && (options === null || options === void 0 ? void 0 : options.enabled) !== false;
  const isInPreloadEnvironment = PRELOAD_ENVIRONMENTS.includes(determineEnvironment().realm);
  const shouldPreload = enabled && isInPreloadEnvironment && isInitialRender;
  const href = shouldPreload ? getClientRequestUrl(url, options) : "";
  useEffect(() => {
    isInitialRender = false;
  }, []);
  useHead(shouldPreload ? jsx("script", {
    dangerouslySetInnerHTML: {
      __html: `
if(!window.requestsInFlight) window.requestsInFlight = new Map()
if(window.fetch && !window.requestsInFlight.has("${href}")) window.requestsInFlight.set("${href}", fetch("${href}"))
        `
    }
  }, `preload$-${url}`) : null);
};
/**
 * Redirects user to login page when upstream return auth error
 * this is useful because we serve static assets and render UI before we know if user is authenticated or not
 * in ideal world this shouldn't be needed as webgateway should do the redirects but that isn't always the case
 */
const useUnauthRedirect = (error, options) => {
  useEffect(() => {
    const isUnauthorisedError = (error === null || error === void 0 ? void 0 : error.status) === 401;
    if (isUnauthorisedError && !(options === null || options === void 0 ? void 0 : options.disableAuthRedirect)) {
      window.location.href = getLoginUrl(window.location.toString());
    }
  }, [error === null || error === void 0 ? void 0 : error.status, options === null || options === void 0 ? void 0 : options.disableAuthRedirect]);
};
/**
 * Will send the custom ForbiddenFetch event when a fetch response
 * encounters a 403
 *
 * This is useful to enable a global handler for showing
 * consistent UI when a user attempts to access a
 * resource they don't have permissions for
 */
const useForbiddenEvent = (error, options) => {
  useEffect(() => {
    if ((error === null || error === void 0 ? void 0 : error.status) !== 403 || (options === null || options === void 0 ? void 0 : options.disableForbiddenEvent)) {
      return;
    }
    window.dispatchEvent(forbiddenEvent);
  }, [error === null || error === void 0 ? void 0 : error.status, options === null || options === void 0 ? void 0 : options.disableForbiddenEvent]);
};
const useQuery = (url, options) => {
  var _a;
  const defaults = useQueryClient().getDefaultOptions();
  const queryKey = createKey(url, options);
  const optionsWithDefaults = {
    queryKey,
    retry: shouldRetry,
    meta: {
      ...options,
      ...((_a = defaults.queries) === null || _a === void 0 ? void 0 : _a.meta),
      ...(options === null || options === void 0 ? void 0 : options.meta)
    },
    staleTime: 3000,
    ...options
  };
  useQueryPreload(url, optionsWithDefaults.meta);
  let result = useQuery$1(optionsWithDefaults);
  useUnauthRedirect(result.error, options);
  useForbiddenEvent(result.error, {
    ...defaults,
    ...options
  });
  result.queryKey = queryKey;
  return result;
};
const getRequestsInFlight = () => {
  const win = window;
  if (!win.requestsInFlight) {
    win.requestsInFlight = new Map();
  }
  return win.requestsInFlight;
};
const getRequestInFlight = (url, meta) => {
  var _a;
  if ((meta === null || meta === void 0 ? void 0 : meta.enabled) === false || typeof window === "undefined") {
    return undefined;
  }
  const href = getClientRequestUrl(url, meta);
  const requestsInFlight = getRequestsInFlight();
  return {
    key: href,
    value: (_a = requestsInFlight.get(href)) === null || _a === void 0 ? void 0 : _a.finally(() => {
      requestsInFlight.delete(href);
    })
  };
};
const setRequestInFlight = (url, options) => {
  const href = getClientRequestUrl(url, options);
  const fetchPromise = clientFetch(url, options);
  // in test environments, we might have requests set to a loading state on purpose
  // in those scenarios, we don't want to dedupe the requests to avoid tests failing because of previous promises not resolving
  const {
    realm
  } = determineEnvironment();
  if (realm === "test") return fetchPromise;
  const requestsInFlight = getRequestsInFlight();
  requestsInFlight.set(href, fetchPromise);
  return fetchPromise.finally(() => requestsInFlight.delete(href));
};
const queryFn = async ({
  queryKey,
  meta,
  pageParam = ""
}) => {
  var _a, _b;
  if (!queryKey) {
    throw new Error("Query key needs to be passed into fetch queryFn");
  }
  // inject pageParam to options and signal for timeouts
  const options = {
    pageParam,
    ...meta
  };
  const url = Array.isArray(queryKey) ? queryKey[0] : queryKey;
  const request = (_b = (_a = getRequestInFlight(url, options)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : setRequestInFlight(url, options);
  const result = await request;
  if (result.ok) return responseBody(result);
  return responseError(result, url);
};
const QueryClientProvider = ({
  children,
  basePath,
  ...defaultOptions
}) => {
  const [queryClient] = React.useState(() => {
    var _a, _b;
    return new QueryClient({
      defaultOptions: {
        ...defaultOptions,
        queries: {
          queryFn,
          ...defaultOptions.queries,
          meta: {
            ...((_a = defaultOptions.queries) === null || _a === void 0 ? void 0 : _a.meta),
            basePath
          }
        },
        mutations: {
          ...defaultOptions.mutations,
          meta: {
            ...((_b = defaultOptions.mutations) === null || _b === void 0 ? void 0 : _b.meta),
            basePath
          }
        }
      }
    });
  });
  useEffect(() => {
    return () => queryClient.clear();
  }, [queryClient]);
  return jsx(QueryClientProvider$1, {
    client: queryClient,
    children: children
  });
};
export { QueryClientProvider, queryFn, useForbiddenEvent, useHead, useQuery, useQueryPreload, useUnauthRedirect };
