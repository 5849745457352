import { useRef, useCallback } from 'react';

/**
 * Injects an empty container into the DOM after the given ref
 * to be used as a target for the skip link
 */
const useMainContent = () => {
  const ref = useRef(null);
  const idOfMainContent = "unified-navigation-main-content";
  const mainContent = useRef();
  const setRef = useCallback(node => {
    if ((ref === null || ref === void 0 ? void 0 : ref.current) && (mainContent === null || mainContent === void 0 ? void 0 : mainContent.current)) {
      ref.current.removeChild(mainContent.current);
    }
    if (node && typeof document !== undefined) {
      mainContent.current = document.createElement("div");
      mainContent.current.setAttribute("id", idOfMainContent);
      mainContent.current.setAttribute("data-testid", idOfMainContent);
      node.appendChild(mainContent.current);
    }
    ref.current = node;
  }, [idOfMainContent]);
  return [setRef, idOfMainContent];
};
export { useMainContent };
