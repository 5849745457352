import { Navigation as NavigationUI } from "@cultureamp/unified-navigation-ui"
import { useIntercom } from "@cultureamp/next-services"

export const Navigation = () => {
  const { toggleIntercomLauncher } = useIntercom()

  // todo figure out why NavigationUI is not prerendered on server
  return (
    <NavigationUI
      onHelpClick={() => toggleIntercomLauncher("showWidget")}
      activeRouteId="home"
    />
  )
}
