import React from "react"
import cx from "classnames"
import styles from "./Spacer.module.scss"

type Gap = 1 | 2 | 3 | 4 | 5

export type SpacerProps = {
  gap: Gap
  gapOnMediumViewports?: Gap
  gapOnSmallViewports?: Gap
}

const Spacer = ({
  gap,
  gapOnMediumViewports,
  gapOnSmallViewports,
}: SpacerProps) => {
  return (
    <div
      className={cx(styles[`gap${gap}`], {
        [styles[`gapOnMediumViewports${gapOnMediumViewports}`]]:
          gapOnMediumViewports,
        [styles[`gapOnSmallViewports${gapOnSmallViewports}`]]:
          gapOnSmallViewports,
      })}
    />
  )
}

export default Spacer
