import { Component } from 'react';
import { reportError } from '../helpers/reporting.mjs';
class ErrorBoundary extends Component {
  constructor() {
    super(...arguments);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError(_) {
    // Update state so the next render will show the fallback UI.
    return {
      hasError: true
    };
  }
  componentDidCatch(error, errorInfo) {
    error.name = "React ErrorBoundary";
    reportError(error);
  }
  render() {
    if (this.state.hasError) {
      return this.props.fallbackComponent;
    }
    return this.props.children;
  }
}
export { ErrorBoundary };
