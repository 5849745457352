import { useState, useCallback } from 'react';
const useLocalStorage = key => {
  const [storedValue, setStoredValue] = useState(() => {
    if (typeof window === "undefined") return null;
    try {
      const storedItem = window.localStorage.getItem(key);
      return storedItem ? JSON.parse(storedItem) : null;
    } catch (_a) {
      return null;
    }
  });
  const storeValue = useCallback(value => {
    if (typeof window === "undefined") return false;
    if (value !== null) {
      window.localStorage.setItem(key, JSON.stringify(value));
      setStoredValue(value);
      return true;
    }
    window.localStorage.removeItem(key);
    setStoredValue(null);
    return true;
  }, [key]);
  return [storedValue, storeValue];
};
export { useLocalStorage as default };
