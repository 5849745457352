import { jsx, jsxs } from 'react/jsx-runtime';
import { Heading } from '@kaizen/components';
const ProfileBanner = ({
  companyName,
  userName
}) => {
  return jsx("div", {
    className: "un-flex un-items-center un-bg-gray-100 un-py-12 un-px-16 un-border-t-1 un-border-t-[rgba(82_78_86)_0.1]",
    children: jsxs("div", {
      className: "un-w-full",
      children: [jsx(Heading, {
        tag: "p",
        variant: "heading-6",
        color: "dark-reduced-opacity",
        children: jsx("span", {
          title: companyName,
          children: companyName
        })
      }), jsx(Heading, {
        tag: "p",
        variant: "heading-5",
        color: "dark",
        children: jsx("span", {
          title: userName,
          children: userName
        })
      })]
    })
  });
};
export { ProfileBanner };
