import { jsx, jsxs } from 'react/jsx-runtime';
import { SkipWhiteIcon } from '@kaizen/components';
import cx from 'classnames';
const SkipLink = ({
  label,
  skipTo
}) => jsx("a", {
  className: cx(
  // button-reset
  "un-appearance-none un-bg-transparent un-m-0 [transition:none]",
  // button
  "un-antialiased un-font-family-data un-font-weight-data un-text-data-units-sm un-leading-data-sm un-tracking-letter-spacing-normal un-box-border un-min-h-48 un-border un-rounded-[7px] un-cursor-pointer un-text-center un-overflow-visible un-no-underline un-py-[calc(12px-2px)] un-px-[calc(24px-2px)]",
  // primary
  "un-bg-white un-border-transparent un-text-purple-800",
  // primary focus
  "focus-visible:after:un-absolute focus-visible:after:un-content-[''] un-bg-white  focus-visible:after:un-bg-transparent focus-visible:after:un-border focus-visible:after:un-rounded-[10px] focus-visible:after:un-border-solid focus-visible:after:un-border-blue-500 focus-visible:after:un-inset-[calc(-1*calc((2px*2)+1px))]",
  // base
  "un-absolute un-top-8 un-left-8 un-z-[1040] [clip:rect(0_0_0_0)] [clip-path:inset(50%)]",
  // focus
  "focus:un-opacity-[1] focus:[clip:auto] focus:[clip-path:none]"),
  href: `#${skipTo}`,
  children: jsxs("span", {
    className: "un-h-full un-flex un-items-center",
    children: [jsx("span", {
      className: "un-h-20 un-pr-4",
      children: jsx(SkipWhiteIcon, {
        role: "presentation"
      })
    }), jsx("span", {
      children: label
    })]
  })
});
export { SkipLink, SkipLink as default };
