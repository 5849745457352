import { useInfiniteQuery } from "@cultureamp/frontend-apis"

// we extracted the load of task list data to its hook to be reusable between tile and old tasks
export const useTaskListData = ({
  filter = "active",
  numberOfTasks,
}: {
  filter?: "active" | "completed"
  numberOfTasks?: number
} = {}) => {
  return useInfiniteQuery("tasks/[filter]", {
    params: { filter },
    query: {
      take: numberOfTasks,
      after: "[pageParam]",
    },
    timeout: 10000,
    initialPageParam: undefined,
    getNextPageParam: lastPage => lastPage.pageInfo.endCursor,
  })
}
