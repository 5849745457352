import { defaultHomeNavItem } from './fallback.mjs';
const isMenu = value => "menuItems" in value;
const isMenuItemGroup = value => "groupTitle" in value;
const getRelativeURL = pathname => pathname.startsWith("/") ? pathname : `/${pathname}`;
const getFirstNavItem = navItems => {
  const firstNavItem = navItems[0];
  if (!firstNavItem) {
    return defaultHomeNavItem;
  }
  if (isMenu(firstNavItem)) {
    const firstMenuItem = firstNavItem.menuItems[0];
    if (firstMenuItem && isMenuItemGroup(firstMenuItem)) {
      const firstMenuGroupItem = firstMenuItem.menuItems[0];
      return firstMenuGroupItem !== null && firstMenuGroupItem !== void 0 ? firstMenuGroupItem : defaultHomeNavItem;
    }
    return firstMenuItem ? firstMenuItem : defaultHomeNavItem;
  }
  return firstNavItem;
};
const getLinkPropsFromFirstNavItem = navItems => {
  const firstItem = getFirstNavItem(navItems);
  return {
    href: getPathnameOrHref(firstItem),
    title: firstItem.title
  };
};
const getPathnameOrHref = linkObject => "pathname" in linkObject ? getRelativeURL(linkObject.pathname) : linkObject.href;
const menuHasActiveChildItem = (menu, activeRouteId) => menu.menuItems.findIndex(item => {
  if (isMenuItemGroup(item)) return item.menuItems.findIndex(el => idIsActive(el.id, activeRouteId)) > -1;
  return idIsActive(item.id, activeRouteId);
}) > -1;
const insertMenuItemSeparators = menuItems => menuItems.flatMap((item, idx) => {
  // don't add separator if it's the last element
  if (idx === menuItems.length - 1) return [item];
  const itemIndex = menuItems[idx + 1];
  // don't add separator if it's a leaf and the next element is also a leaf
  if (!isMenuItemGroup(item) && itemIndex && !isMenuItemGroup(itemIndex)) return [item];
  return [item, `separator-${idx}`];
});
const containsNumeral = text => /\d/.test(text);
const idIsActive = (id, activeRouteId) => {
  if (!activeRouteId) return false;
  if (Array.isArray(activeRouteId)) {
    return activeRouteId.some(aId => aId === id);
  }
  return activeRouteId === id;
};
/*
  Group menu items that don't have a groupTitle to prevent nested li causing invalid html
  Resulting array contains elements with either
  {
    id: string,
    menuItems: link[]
  }
  or
  {
    id: string
    groupTitle: string
    menuItems: Link[]
  }
*/
const groupMenuItems = menuItems => {
  return menuItems.reduce((groups, item) => {
    var _a, _b;
    if (isMenuItemGroup(item)) {
      return (groups || []).concat(item);
    }
    if (groups.length) {
      (_b = (_a = groups[groups.length - 1]) === null || _a === void 0 ? void 0 : _a.menuItems) === null || _b === void 0 ? void 0 : _b.push(item);
      return groups;
    }
    return [{
      id: item.id,
      menuItems: [item]
    }];
  }, []);
};
export { containsNumeral, getLinkPropsFromFirstNavItem, getPathnameOrHref, groupMenuItems, idIsActive, insertMenuItemSeparators, isMenu, isMenuItemGroup, menuHasActiveChildItem };
