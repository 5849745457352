import { LoadingHeading, LoadingParagraph } from "@kaizen/components"
import { TileContainer } from "../TileBlock"
import { useIsSmall } from "../../helpers/responsiveHooks"
import React from "react"
import Spacer from "../Spacer/Spacer"

export const TileLoadingMedium = () => {
  const isSmall = useIsSmall()
  return (
    <TileContainer>
      <LoadingHeading variant="heading-3" width={isSmall ? 60 : 43} />
      <Spacer gap={1} />
      <LoadingParagraph width={isSmall ? 100 : 80} />
      <LoadingParagraph width={isSmall ? 80 : 65} />
      <LoadingParagraph width={isSmall ? 80 : 70} />
    </TileContainer>
  )
}
