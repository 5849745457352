import React, { PropsWithChildren } from "react"
import cx from "classnames"
import styles from "./ListContainer.module.scss"

export const ListContainer: React.FC<
  PropsWithChildren<{
    className?: string
  }>
> = props => {
  return (
    <ul className={cx(styles.listContainer, props.className)}>
      {props.children}
    </ul>
  )
}
