import { LoadingHeading, LoadingParagraph } from "@kaizen/components"
import { useIsSmall } from "../../helpers/responsiveHooks"
import React from "react"
import Spacer from "../Spacer/Spacer"

export const TileLoadingLarge = () => {
  const isSmall = useIsSmall()

  return (
    <>
      <LoadingHeading variant="heading-3" width={isSmall ? 60 : 20} />
      <Spacer gap={1} />
      <LoadingParagraph width={isSmall ? 100 : 60} />
      <LoadingParagraph width={isSmall ? 80 : 50} />
    </>
  )
}
