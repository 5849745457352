import { jsx, jsxs, Fragment } from 'react/jsx-runtime';
import { AmpliProvider } from '@cultureamp/analytics';
import { ErrorBoundary } from '../ErrorBoundary/ErrorBoundary.mjs';
import { LegacyMobileNavigationTrigger } from '../LegacyMobileNavigationTrigger/LegacyMobileNavigationTrigger.mjs';
import { NavigationBar } from '../NavigationBar/NavigationBar.mjs';
import { NavigationMobile, toggleMobileNavigation } from '../NavigationMobile/NavigationMobile.mjs';
import { QueryClientProvider, useQuery } from '../../hooks/index.mjs';
import { Tag } from '@kaizen/components';
import { ampli } from '../../ampli/index.mjs';
import { devFallbackItems, fallbackItems } from '../helpers/fallback.mjs';
import { getQueryLocale } from '../helpers/localization.mjs';
import { isLocalhostEnvironment } from '../helpers/environment.mjs';
import { useEffect } from 'react';
import SuperuserNav from '../SuperuserNav/SuperuserNav.mjs';
import useLocalStorage from '../helpers/localStorage.mjs';
const openMobileNavigation = () => toggleMobileNavigation("open");
const Navigation = props => {
  const {
    variant,
    ...rest
  } = props;
  return isLocalhostEnvironment() || props.environment === "testing" ? jsx(LocalNavigation, {
    ...rest,
    variant: "local"
  }) : jsx(AmpliProvider, {
    ampli: ampli,
    children: jsx(QueryClientProvider, {
      basePath: "app/navigation",
      children: jsx(FetchedNavigation, {
        ...props
      })
    })
  });
};
const LocalNavigation = props => {
  const {
    mobileNavigationTrigger,
    ...rest
  } = props;
  return jsxs(Fragment, {
    children: [jsxs("div", {
      className: "un-block lg:un-hidden",
      children: [mobileNavigationTrigger && mobileNavigationTrigger(openMobileNavigation), " ", jsx(NavigationMobile, {
        loading: false,
        ...rest,
        navigationData: devFallbackItems
      })]
    }), jsx("div", {
      className: "un-hidden lg:un-block",
      children: jsx(NavigationBar, {
        extraUtility: jsx(Tag, {
          children: "Local"
        }),
        loading: false,
        ...rest,
        navigationData: devFallbackItems
      })
    })]
  });
};
const FallbackNavigation = ({
  cacheFallback,
  variant,
  textDirection,
  mobileNavigationTrigger
}) => {
  const fallbackData = cacheFallback !== null && cacheFallback !== void 0 ? cacheFallback : fallbackItems;
  return jsxs(Fragment, {
    children: [jsxs("div", {
      className: "un-block lg:un-hidden",
      children: [mobileNavigationTrigger && jsx(LegacyMobileNavigationTrigger, {
        onTrigger: openMobileNavigation
      }), jsx(NavigationMobile, {
        textDirection: textDirection,
        loading: false,
        navigationData: fallbackData
      })]
    }), jsx("div", {
      className: "un-hidden lg:un-block",
      children: jsx(NavigationBar, {
        textDirection: textDirection,
        loading: false,
        navigationData: fallbackData,
        variant: variant
      })
    })]
  });
};
const FetchedNavigation = props => {
  var _a, _b, _c;
  const {
    mobileNavigationTrigger,
    accessedAccountId,
    accessedAccountName
  } = props;
  const [storedNav, storeNav] = useLocalStorage("cache:nav");
  const {
    data,
    isLoading: loading,
    isError
  } = useQuery("navigation", {
    query: {
      locale: getQueryLocale()
    },
    disableAuthRedirect: true,
    disableQueryPreload: true
  }); // remove this type assertion when migrating back to frontend-apis
  const navigationData = (data === null || data === void 0 ? void 0 : data.navData) || storedNav || fallbackItems;
  const isWarmup = !data && !isError;
  const textDirection = (_c = (_b = (_a = props.textDirection) !== null && _a !== void 0 ? _a : data === null || data === void 0 ? void 0 : data.textDirection) !== null && _b !== void 0 ? _b : storedNav === null || storedNav === void 0 ? void 0 : storedNav.textDirection) !== null && _c !== void 0 ? _c : undefined;
  const fallbackProps = {
    cacheFallback: storedNav,
    variant: props.variant,
    textDirection,
    mobileNavigationTrigger
  };
  useEffect(() => {
    const navData = data === null || data === void 0 ? void 0 : data.navData;
    navData && storeNav({
      ...fallbackItems,
      navItems: navData.navItems,
      textDirection: data === null || data === void 0 ? void 0 : data.textDirection
    });
  }, [data, storeNav]);
  return jsx(ErrorBoundary, {
    fallbackComponent: jsx(FallbackNavigation, {
      ...fallbackProps
    }),
    children: isWarmup ? jsx(FallbackNavigation, {
      ...fallbackProps
    }) : jsxs(Fragment, {
      children: [jsxs("div", {
        className: "un-block lg:un-hidden",
        children: [mobileNavigationTrigger && mobileNavigationTrigger(openMobileNavigation), jsx(NavigationMobile, {
          ...props,
          loading,
          navigationData,
          textDirection: textDirection
        })]
      }), jsxs("div", {
        className: "un-hidden lg:un-block",
        children: [jsx(NavigationBar, {
          ...props,
          loading,
          navigationData,
          textDirection: textDirection
        }), jsx(SuperuserNav, {
          isSuperuser: !!(data === null || data === void 0 ? void 0 : data.isSuperuser),
          accountId: data === null || data === void 0 ? void 0 : data.accountId,
          accessedAccountId: accessedAccountId,
          accessedAccountName: accessedAccountName
        })]
      })]
    })
  });
};
export { FetchedNavigation, LocalNavigation, Navigation };
