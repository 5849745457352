import { QuestionIcon, CommunicationsIcon } from '@kaizen/components';
const pickIcon = icon => {
  switch (icon) {
    case "communications":
      return CommunicationsIcon;
    case "question":
      return QuestionIcon;
    default:
      return QuestionIcon;
  }
};
const getHelpItem = (newHelpItem, oldHelpLabel) => (newHelpItem === null || newHelpItem === void 0 ? void 0 : newHelpItem.isEnabled) ? {
  icon: pickIcon(newHelpItem.icon),
  label: newHelpItem.label
} : {
  icon: QuestionIcon,
  label: oldHelpLabel || "Help"
};
export { getHelpItem };
