import { determineEnvironment } from '@cultureamp/frontend-env/lib';
const SUPERUSER_BROWSABLE_PAGES_BY_ACCOUNT = ["/account/", "/admin/", "/app/programs/", "/app/employee-imports", "/app/employee-data-integrations", "/app/org-builder", "/app/product-modules", "/app/roles", "/background_jobs", "/app/users/account"];
const SUPERUSER_BROWSABLE_PAGES_BY_SURVEY = ["/surveys/", "/effectiveness/surveys/", "/insight_report/", "/activity/", "/features/"];
const SUPERUSER_BROWSABLE_PAGES = [...SUPERUSER_BROWSABLE_PAGES_BY_ACCOUNT, ...SUPERUSER_BROWSABLE_PAGES_BY_SURVEY];
const ID_REGEX = new RegExp("([a-f0-9]{8}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{4}-[a-f0-9]{12})", "g");
// Matches AggregateID from URL
const matchFirstIdByRegex = (location, regex) => {
  var _a;
  const ids = location.pathname.match(regex);
  return (_a = ids && ids[0]) !== null && _a !== void 0 ? _a : null;
};
const matchIdByParams = (location, param) => {
  const params = new URLSearchParams(location.search);
  const id = params.get(param);
  if (id === null || id === void 0 ? void 0 : id.match(ID_REGEX)) return id;
  return null;
};
const matchIdFromUrl = () => {
  const location = typeof window !== "undefined" ? window.location : null;
  if (!location) return null;
  return matchIdByParams(location, "active_account_id") || matchIdByParams(location, "account_aggregate_id") || matchFirstIdByRegex(location, ID_REGEX);
};
const isSuperuserBrowsablePage = isSuperuser => {
  const location = typeof window !== "undefined" ? window.location : null;
  const env = determineEnvironment().realm;
  if (isSuperuser && (env === "test" || env === "localhost")) return true;
  if (!location) return false;
  return isSuperuser && SUPERUSER_BROWSABLE_PAGES.some(prefix => location.pathname.startsWith(prefix));
};
export { isSuperuserBrowsablePage, matchFirstIdByRegex, matchIdByParams, matchIdFromUrl };
