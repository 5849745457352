import { formatWithValidation } from "next/dist/shared/lib/router/utils/format-url"
import { parseRelativeUrl } from "next/dist/shared/lib/router/utils/parse-relative-url"
import { useRouter } from "next/router"
import React from "react"
import type { UrlObject } from "url"

export const withQuery = (
  href: string | UrlObject,
  query: Record<string, undefined | string | string[]>,
  locale?: string | false,
) => {
  const urlAsString =
    typeof href === "string" ? href : formatWithValidation(href)
  const url = parseRelativeUrl(urlAsString)

  if (query.locale && !url.query.locale && locale !== false) {
    url.query.locale = locale ?? query.locale?.toString()
  }

  return url
}

// custom hook to allow us to preseve desired query between navigation
// it is bit clunky but there currently isn't other option
//
// but adding option to next should be super simple. There already is router.beforePopState which allows to block navigation.
// we could simply allow this function to return new object instead of just boolean that modifies the nav
// it shouldn't take much change, just few lines here https://github.com/vercel/next.js/blob/0441f816a668fdfef19e1b35a6fc7333e0bdcf87/packages/next/shared/lib/router/router.ts#L852-L854
// and it would be backward compatible so it shouldn't be problem to get accepted
export const useNavigate = () => {
  const router = useRouter()

  return React.useCallback(
    (...[url, asUrl, options]: Parameters<(typeof router)["push"]>) => {
      return router.push(
        withQuery(url, router.query, options?.locale),
        asUrl ? withQuery(asUrl, router.query, options?.locale) : asUrl,
        options,
      )
    },
    [router],
  )
}
