import { Box } from "@kaizen/component-library"
import { ListContainer } from "src/components/ListContainer/ListContainer"
import { TileLoadingLarge } from "src/components/TileLoading"
import React from "react"
import styles from "./LoadingList.module.scss"

export const LoadingList = () => {
  return (
    <ListContainer className={styles.loadingContainer}>
      <li>
        <Box px={1.5} py={1}>
          <TileLoadingLarge />
        </Box>
      </li>
      <li>
        <Box px={1.5} py={1}>
          <TileLoadingLarge />
        </Box>
      </li>
      <li>
        <Box px={1.5} py={1}>
          <TileLoadingLarge />
        </Box>
      </li>
    </ListContainer>
  )
}
