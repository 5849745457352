import { Icon } from "@kaizen/component-library"
import { Paragraph } from "@kaizen/typography"
import React from "react"
import arrowDownIcon from "@kaizen/component-library/icons/arrow-down.icon.svg"
import arrowUpIcon from "@kaizen/component-library/icons/arrow-up.icon.svg"
import classnames from "classnames"
import styles from "./TileStat.module.scss"

interface TileStatProps {
  value: number | null | undefined
  icon?: React.ReactElement
  label: string | React.ReactElement | null | undefined
  units?: string
  changeInValue?: number | null
  showChangeInValue?: boolean
  textAlignment?: "left" | "center" | "right"
}

export const TileStat: React.FC<TileStatProps> = ({
  icon,
  value,
  label,
  units,
  textAlignment = "left",
  changeInValue,
  showChangeInValue = true,
}) => {
  if (value === null || value === undefined || isNaN(value)) return null

  return (
    <div className={classnames(styles.statistic, styles[textAlignment])}>
      <div className={styles.value}>
        {value}
        {units && <span className={styles.units}>{units}</span>}
        {!!changeInValue && (
          <Paragraph
            variant="body"
            color={changeInValue > 0 ? "positive" : "negative"}
          >
            <Icon
              icon={changeInValue > 0 ? arrowUpIcon : arrowDownIcon}
              title={changeInValue > 0 ? "Arrow Up" : "Arrow Down"}
              role="presentation"
            />
            {showChangeInValue && changeInValue}
          </Paragraph>
        )}
      </div>
      {label && (
        <div className={classnames({ [styles.iconWithText]: icon })}>
          <Paragraph
            classNameOverride={styles.label}
            variant="body"
            color="dark-reduced-opacity"
          >
            {icon}
            {typeof label === "string" ? <span>{label}</span> : label}
          </Paragraph>
        </div>
      )}
    </div>
  )
}
