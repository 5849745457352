import { Heading } from "@kaizen/components"
import React, { FC, ReactNode } from "react"

export enum TagAlignment {
  INLINE = "inline",
  SPACE_BETWEEN = "spaceBetween",
}
interface TileHeaderProps {
  title: ReactNode | string
  titleId?: string
  tag?: ReactNode | null
  tagAlignment?: TagAlignment
}

const TagContainer: FC<{ children: ReactNode }> = ({ children }) => (
  <div className="[&>*]:m-0 inline-block translate-y-1">{children}</div>
)

export const TileHeader: FC<TileHeaderProps> = ({
  title,
  titleId,
  tag,
  tagAlignment = TagAlignment.SPACE_BETWEEN,
}) => {
  const containerClasses = {
    inline: "inline-flex flex-wrap items-center gap-8",
    spaceBetween: "flex flex-wrap justify-between gap-8",
  }

  return (
    <div className={`${containerClasses[tagAlignment]}`}>
      <Heading
        variant="heading-3"
        classNameOverride={
          tag && tagAlignment === TagAlignment.INLINE
            ? containerClasses["inline"]
            : ""
        }
      >
        <span id={titleId}>{title}</span>
        {tag && tagAlignment === TagAlignment.INLINE && (
          <TagContainer>{tag}</TagContainer>
        )}
      </Heading>
      {tag && tagAlignment === TagAlignment.SPACE_BETWEEN && (
        <TagContainer>{tag}</TagContainer>
      )}
    </div>
  )
}
