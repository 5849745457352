import { jsxs, jsx } from 'react/jsx-runtime';
import { ampli } from '../../ampli/index.mjs';
import cx from 'classnames';
const UtilityItem = ({
  id,
  showLabel,
  href,
  onClick,
  title,
  Icon
}) => {
  const onClickWithTracking = event => {
    onClick && onClick(event);
    id && ampli.navigationBarClicked({
      "Menu item": id,
      "Menu header": undefined
    });
  };
  const itemClasses = cx(
  // utilityButton
  "un-font-family-heading un-font-weight-paragraph-bold un-text-heading-5 un-leading-heading-5 un-tracking-normal",
  // default
  "[.default_&]:un-text-white/[0.7]", "[.local_&]:un-text-white/[0.8]",
  // education
  "[.education_&]:un-text-blue-500",
  //admin
  "[.admin_&]:un-text-blue-500",
  // need class
  "navigationItem", "un-h-72 un-relative un-flex un-items-center un-justify-center un-py-0 un-px-16 un-font-family-heading un-font-weight-paragraph-bold un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal un-bg-transparent un-border-none [text-decoration:none]",
  //.tier4/5
  "[.tier4_&]:un-text-heading-5 [.tier4_&]:un-py-0 [.tier4_&]:un-px-12", "[.tier5_&]:un-text-heading-6 [.tier5_&]:un-py-0 [.tier5_&]:un-px-12", "focus:un-outline-none");
  const itemInner = jsxs("span", {
    className: cx("un-relative un-flex un-items-center",
    // hover focus before
    "[.navigationItem:hover_&]:before:un-bg-white/[0.1] [.navigationItem:hover_&]:before:un-content-[''] [.navigationItem:hover_&]:before:-un-inset-x-16 [.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:hover_&]:before:un-absolute [.navigationItem:hover_&]:before:un-rounded-[7px]", "[.navigationItem:focus_&]:before:un-bg-white/[0.1] [.navigationItem:focus_&]:before:un-content-[''] [.navigationItem:focus_&]:before:-un-inset-x-16 [.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus_&]:before:un-absolute [.navigationItem:focus_&]:before:un-rounded-[7px]",
    // tier4/5
    "[.tier4_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:hover_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier4_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]", "[.tier5_.navigationItem:focus_&]:before:un-inset-x-[calc(0.75rem*-1)]",
    // admin
    "[.admin_.navigationItem:hover_&]:before:un-bg-blue-100 [.admin_.navigationItem:hover_&]:before:un-content-[''] [.admin_.navigationItem:hover_&]:before:-un-inset-x-16 [.admin_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:hover_&]:before:un-absolute [.admin_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.admin_.navigationItem:focus_&]:before:un-bg-blue-100 [.admin_.navigationItem:focus_&]:before:un-content-[''] [.admin_.navigationItem:focus_&]:before:-un-inset-x-16 [.admin_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.admin_.navigationItem:focus_&]:before:un-absolute [.admin_.navigationItem:focus_&]:before:un-rounded-[7px]",
    // education
    "[.education_.navigationItem:hover_&]:before:un-bg-white/[0.85] [.education_.navigationItem:hover_&]:before:un-content-[''] [.education_.navigationItem:hover_&]:before:-un-inset-x-16 [.education_.navigationItem:hover_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:hover_&]:before:un-absolute [.education_.navigationItem:hover_&]:before:un-rounded-[7px]", "[.education_.navigationItem:focus_&]:before:un-bg-white/[0.85] [.education_.navigationItem:focus_&]:before:un-content-[''] [.education_.navigationItem:focus_&]:before:-un-inset-x-16 [.education_.navigationItem:focus_&]:before:un-inset-y-[calc(0.75rem*-1)] [.education_.navigationItem:focus_&]:before:un-absolute [.education_.navigationItem:focus_&]:before:un-rounded-[7px]",
    // focus ring
    "[.navigationItem:focus-visible_&]:after:un-content-[''] [.navigationItem:focus-visible_&]:after:-un-inset-x-16 [.navigationItem:focus-visible_&]:after:un-inset-y-[calc(0.75rem*-1)] [.navigationItem:focus-visible_&]:after:un-absolute [.navigationItem:focus-visible_&]:after:un-rounded-focus-ring [.navigationItem:focus-visible_&]:after:un-m-[calc(calc((calc(2px*2))+1px)*-1)] [.navigationItem:focus-visible_&]:after:un-border [.navigationItem:focus-visible_&]:after:un-border-solid [.navigationItem:focus-visible_&]:after:un-border-blue-200", "[.education_.navigationItem:focus-visible_&]:after:un-border-blue-500"),
    children: [jsx("span", {
      className: "un-flex un-items-center un-translate-x-0",
      children: showLabel ? jsx(Icon, {
        role: "presentation"
      }) : jsx(Icon, {
        role: "img",
        "aria-label": title
      })
    }), showLabel && jsx("span", {
      className: "un-translate-x-0 un-ml-6 [dir='rtl'_&]:un-ml-0 [dir='rtl'_&]:un-mr-6",
      children: title
    })]
  });
  return href ? jsx("a", {
    href: href,
    onClick: onClickWithTracking,
    className: itemClasses,
    children: itemInner
  }) : jsx("button", {
    onClick: onClickWithTracking,
    className: itemClasses,
    children: itemInner
  });
};
export { UtilityItem };
