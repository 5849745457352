import { Box } from "@kaizen/component-library"
import { Divider } from "@kaizen/components"
import React, { PropsWithChildren } from "react"
import styles from "./TileFooter.module.scss"

export const TileFooter: React.FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className={styles.footer}>
    <Divider variant="canvas" />
    <Box mt={1} mb={0.25}>
      {children}
    </Box>
  </div>
)
