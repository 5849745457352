import { jsxs, jsx, Fragment } from 'react/jsx-runtime';
import { useRef, useState, useEffect } from 'react';
import { SpinnerIcon, IconButton, CaMonogramIcon, Heading, ArrowBackwardIcon, CloseIcon, Divider, SettingsIcon, NotificationIcon, ArrowRightIcon, VisuallyHidden } from '@kaizen/components';
import { Transition } from '@headlessui/react';
import FocusLock from 'react-focus-lock';
import { createEventBus, useEventBus } from '@cultureamp/event-bus';
import { getLinkPropsFromFirstNavItem, isMenu, isMenuItemGroup, insertMenuItemSeparators, groupMenuItems, idIsActive, getPathnameOrHref, menuHasActiveChildItem } from '../helpers/uiHelpers.mjs';
import { httpRequest } from '../helpers/httpRequestHelper.mjs';
import cx from 'classnames';
import { ProfileBanner } from '../ProfileBanner/ProfileBanner.mjs';
import { ampli } from '../../ampli/index.mjs';
import { getHelpItem } from '../helpers/getHelpItem.mjs';
import createAriaHider from './createAriaHider.mjs';
import isChromatic from 'chromatic/isChromatic';
const {
  subscribeFn,
  publishFn
} = createEventBus("TOGGLE_MOBILE_NAVIGATION");
const toggleMobileNavigation = publishFn;
const onMobileNavigationToggle = subscribeFn;
const closeMobileNavigation = () => toggleMobileNavigation("closed");
const Link = ({
  onClick,
  link,
  activeRouteId,
  Icon,
  groupTitle
}) => {
  const isActive = idIsActive(link.id, activeRouteId);
  return jsxs("a", {
    className: cx("un-w-full un-flex un-h-48 un-items-center un-box-border un-border-none [text-decoration:none] un-py-0 un-px-12 un-rounded-default un-font-family-data un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal",
    // hover
    "hover:un-cursor-pointer hover:un-bg-blue-100",
    // active
    isActive ? "un-bg-blue-100 un-text-blue-500 un-font-weight-paragraph-bold" : "un-text-purple-800",
    // focus
    "focus:un-outline focus:un-outline-2 focus:un-outline-blue-500"),
    "aria-current": isActive ? "page" : undefined,
    onClick: event => {
      onClick && onClick(event);
      ampli.navigationBarClicked({
        "Menu item": link.id,
        "Menu header": groupTitle
      });
      if ("method" in link && link.method && !event.defaultPrevented) {
        event.preventDefault();
        httpRequest(link, {});
      }
    },
    href: getPathnameOrHref(link),
    children: [Icon && jsx("span", {
      className: "un-flex un-pe-12",
      children: jsx(Icon, {
        role: "presentation"
      })
    }), "mobileTitle" in link ? link.mobileTitle : link.title]
  });
};
const MenuTrigger = ({
  menu,
  onClick,
  activeRouteId
}) => {
  const hasActiveChild = menuHasActiveChildItem(menu, activeRouteId);
  return jsxs("button", {
    className: cx("un-w-full un-flex un-h-48 un-items-center un-box-border un-border-none [text-decoration:none] un-py-0 un-px-12 un-rounded-default un-font-family-data un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal",
    // hover
    "hover:un-cursor-pointer hover:un-bg-blue-100",
    // active
    hasActiveChild ? "un-bg-blue-100 un-text-blue-500 un-font-weight-paragraph-bold" : "un-bg-transparent un-text-purple-800",
    // focus
    "focus:un-outline focus:un-outline-2 focus:un-outline-blue-500"),
    onClick: () => onClick(menu),
    children: [menu.title, jsx("span", {
      className: "un-flex un-ml-auto rtl:un-ml-0 rtl:un-mr-auto",
      children: jsx(ArrowRightIcon, {
        role: "presentation"
      })
    }), hasActiveChild && jsx(VisuallyHidden, {
      children: "current section"
    })]
  });
};
const MenuItemGroup = ({
  menuItemGroup,
  link
}) => jsxs("div", {
  className: "un-pt-16 un-pb-12",
  children: [(menuItemGroup === null || menuItemGroup === void 0 ? void 0 : menuItemGroup.groupTitle) && jsx("span", {
    className: "un-block un-py-0 un-px-12",
    children: jsx(Heading, {
      tag: "p",
      variant: "heading-6",
      children: menuItemGroup.groupTitle
    })
  }), jsx("ul", {
    className: "un-list-none un-m-0 un-p-0",
    children: menuItemGroup.menuItems.map(link)
  })]
});
const NavigationMobile = ({
  navigationData,
  onNavigationLinkClick,
  activeRouteId,
  textDirection,
  onHelpClick,
  loading
}) => {
  const modalLayerRef = useRef(null);
  const headingRef = useRef(null);
  const subMenuHeadingRef = useRef(null);
  const mobileNavigationState = useEventBus(onMobileNavigationToggle, "closed");
  const [removeAriaHider, setRemoveAriaHider] = useState(null);
  const [currentMenu, setCurrentMenu] = useState("topLevel");
  useEffect(() => {
    function onKeyup(e) {
      if (e.key === "Escape") closeMobileNavigation();
    }
    window.addEventListener("keyup", onKeyup);
    return () => window.removeEventListener("keyup", onKeyup);
  }, []);
  useEffect(() => {
    var _a, _b;
    if (currentMenu !== "topLevel") {
      (_a = subMenuHeadingRef === null || subMenuHeadingRef === void 0 ? void 0 : subMenuHeadingRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    } else {
      (_b = headingRef === null || headingRef === void 0 ? void 0 : headingRef.current) === null || _b === void 0 ? void 0 : _b.focus();
    }
  }, [currentMenu]);
  const {
    helpItem,
    helpLabel
  } = navigationData;
  const helpItemData = getHelpItem(helpItem, helpLabel);
  const HelpIcon = helpItemData.icon;
  const onLinkClick = e => {
    closeMobileNavigation();
    onNavigationLinkClick && onNavigationLinkClick(e);
    // TODO: Design token to match animation speed.
    setTimeout(() => {
      setCurrentMenu("topLevel");
    }, isChromatic() ? 0 : 300);
  };
  // Renders level 1 of the navigation
  const renderTopLevelNavigationItems = navItems => jsxs(Fragment, {
    children: [jsx("ul", {
      className: "un-list-none un-m-0 un-p-0",
      children: navItems.map(navItem => {
        if (isMenu(navItem)) {
          return jsx("li", {
            children: jsx(MenuTrigger, {
              menu: navItem,
              activeRouteId: activeRouteId,
              onClick: setCurrentMenu
            }, navItem.title)
          }, navItem.id);
        }
        return jsx("li", {
          children: jsx(Link, {
            activeRouteId: activeRouteId,
            onClick: onLinkClick,
            link: navItem
          }, navItem.id)
        }, navItem.id);
      })
    }), (navigationData.settingsItem || navigationData.profileItem || navigationData.superuserItem || onHelpClick) && jsx("div", {
      className: "un-my-20 un-mx-0",
      children: jsx(Divider, {
        variant: "menuSeparator"
      })
    }), jsxs("ul", {
      className: "un-list-none un-m-0 un-p-0",
      children: [navigationData.settingsItem && jsx("li", {
        children: jsx(Link, {
          Icon: SettingsIcon,
          onClick: onLinkClick,
          link: navigationData.settingsItem
        })
      }), onHelpClick && jsx("li", {
        children: jsxs("button", {
          onClick: () => {
            closeMobileNavigation();
            onHelpClick();
            ampli.helpToolInitiated();
          },
          className: cx("un-w-full un-flex un-h-48 un-items-center un-box-border un-border-none un-bg-transparent un-text-purple-800 [text-decoration:none] un-py-0 un-px-12 un-rounded-default un-font-family-data un-text-heading-4 un-leading-heading-4 un-tracking-letter-spacing-normal",
          // hover
          "hover:un-cursor-pointer hover:un-bg-blue-100",
          // focus
          "focus:un-outline focus:un-outline-2 focus:un-outline-blue-500"),
          children: [jsx("span", {
            className: "un-flex un-pr-12 un-pl-0 rtl:un-pr-0 rtl:un-pl-12",
            children: jsx(HelpIcon, {
              role: "presentation"
            })
          }), helpItemData.label]
        })
      }), navigationData.notificationsItem && jsx("li", {
        children: jsx(Link, {
          Icon: NotificationIcon,
          onClick: onLinkClick,
          link: navigationData.notificationsItem
        }, navigationData.notificationsItem.id)
      }), navigationData.profileItem && jsx("li", {
        children: jsx(MenuTrigger, {
          menu: {
            id: "profile",
            title: navigationData.profileItem.title,
            menuItems: navigationData.profileItem.menuItems
          },
          activeRouteId: activeRouteId,
          onClick: setCurrentMenu
        })
      }), navigationData.superuserItem && jsx("li", {
        children: jsx(MenuTrigger, {
          menu: {
            id: "superuser",
            title: navigationData.superuserItem.title,
            menuItems: navigationData.superuserItem.menuItems
          },
          activeRouteId: activeRouteId,
          onClick: setCurrentMenu
        })
      })]
    })]
  });
  // Renders from level 2 of a specific Menu
  const renderSecondLevelNavigationItems = menu => {
    const menuItems = menu.menuItems;
    const containsItemGroups = menuItems.find(item => isMenuItemGroup(item));
    if (containsItemGroups) {
      return insertMenuItemSeparators(groupMenuItems(menuItems)).map(menuItem => {
        if (typeof menuItem === "string") return jsx(Divider, {
          variant: "menuSeparator"
        }, menuItem);
        return jsx(MenuItemGroup, {
          menuItemGroup: menuItem,
          link: link => jsx("li", {
            children: jsx(Link, {
              link: link,
              groupTitle: menu.title,
              activeRouteId: activeRouteId,
              onClick: onLinkClick
            })
          }, link.id)
        }, menuItem.id);
      });
    }
    return jsx("ul", {
      className: "un-list-none un-m-0 un-p-0",
      children: menuItems.map(menuItem => {
        if (isMenuItemGroup(menuItem)) {
          // not possible because of the `containsItemGroups` check above, but TS can't work that out
          return;
        }
        return jsx("li", {
          children: jsx(Link, {
            link: menuItem,
            activeRouteId: activeRouteId,
            groupTitle: menu.title,
            onClick: onLinkClick
          })
        }, menuItem.id);
      })
    });
  };
  const homeLink = getLinkPropsFromFirstNavItem(navigationData.navItems);
  return jsxs(Transition, {
    afterEnter: () => {
      var _a;
      (_a = headingRef === null || headingRef === void 0 ? void 0 : headingRef.current) === null || _a === void 0 ? void 0 : _a.focus();
      (modalLayerRef === null || modalLayerRef === void 0 ? void 0 : modalLayerRef.current) && setRemoveAriaHider(() => createAriaHider(modalLayerRef === null || modalLayerRef === void 0 ? void 0 : modalLayerRef.current, true));
    },
    afterLeave: () => {
      removeAriaHider && removeAriaHider();
      setCurrentMenu("topLevel");
    },
    show: mobileNavigationState === "open",
    children: [jsx(Transition.Child, {
      className: "un-fixed un-bg-black un-opacity-50 un-inset-0 un-z-[1040]",
      enter: "un-transition-opacity un-duration-300 un-ease-[cubic-bezier(0.455,0.03,0.515,0.955)]",
      leave: "un-transition-opacity un-duration-300 un-ease-[cubic-bezier(0.455,0.03,0.515,0.955)]",
      enterFrom: "un-opacity-0",
      enterTo: "un-opacity-50",
      leaveFrom: "un-opacity-50",
      leaveTo: "un-opacity-0",
      onClick: () => toggleMobileNavigation("closed")
    }), jsx(Transition.Child, {
      as: "nav",
      dir: textDirection,
      className: "un-fixed un-h-screen un-w-full un-max-w-[375px] un-top-0 un-left-0 un-bg-white un-text-purple-800 un-shadow-sm un-z-[1050] un-flex un-flex-col un-antialiased",
      enter: "un-transition-[left] un-duration-300 un-ease-[cubic-bezier(0.455,0.03,0.515,0.955)]",
      leave: "un-transition-[left] un-duration-300 un-ease-[cubic-bezier(0.455,0.03,0.515,0.955)]",
      enterFrom: "un-left-[-100%]",
      enterTo: "un-left-0",
      leaveFrom: "un-left-0",
      leaveTo: "un-left-[-100%]",
      children: jsxs(FocusLock, {
        returnFocus: true,
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus: false,
        ref: modalLayerRef,
        className: "un-h-full",
        children: [jsxs("div", {
          className: "un-flex-auto un-bg-purple-600 un-text-white un-h-[60px] un-flex un-justify-between un-items-center un-py-0 un-px-6",
          children: [currentMenu === "topLevel" ? jsxs(Fragment, {
            children: [loading ? jsx("span", {
              className: "un-flex un-justify-center un-items-center un-w-48",
              children: jsx(SpinnerIcon, {
                "aria-label": "Loading",
                role: "img"
              })
            }) : jsx(IconButton, {
              href: homeLink.href,
              reversed: true,
              label: `Culture Amp ${homeLink.title}`,
              icon: jsx(CaMonogramIcon, {
                role: "presentation"
              })
            }), jsx("div", {
              className: cx("un-relative un-outline-none",
              // focus
              "focus-visible:after:un-content-[''] focus-visible:after:un-absolute focus-visible:after:un-bg-transparent focus-visible:after:un-rounded-[10px] focus-visible:after:un-border focus-visible:after:un-border-solid focus-visible:after:un-border-blue-300 focus-visible:after:un-inset-[calc(-1*calc((2px*2)+5px))]"),
              ref: headingRef,
              tabIndex: -1,
              children: jsx(Heading, {
                tag: "h2",
                variant: "heading-3",
                color: "white",
                children: navigationData.menuLabel || "Menu"
              })
            })]
          }) : jsxs(Fragment, {
            children: [jsx(IconButton, {
              reversed: true,
              onClick: () => setCurrentMenu("topLevel"),
              label: "Back to Main Menu",
              icon: jsx(ArrowBackwardIcon, {
                role: "presentation"
              })
            }), jsx("div", {
              ref: subMenuHeadingRef,
              tabIndex: -1,
              children: jsx(Heading, {
                tag: "h2",
                variant: "heading-3",
                color: "white",
                children: currentMenu.title
              })
            })]
          }), jsx(IconButton, {
            reversed: true,
            onClick: closeMobileNavigation,
            label: "Close Menu",
            icon: jsx(CloseIcon, {
              role: "presentation"
            })
          })]
        }), jsxs("div", {
          className: "un-h-full un-overflow-y-scroll un-flex un-flex-col un-justify-between",
          children: [jsx("div", {
            className: "un-p-12",
            children: currentMenu === "topLevel" ? renderTopLevelNavigationItems(navigationData.navItems) : renderSecondLevelNavigationItems(currentMenu)
          }), navigationData.profileItem && jsx(ProfileBanner, {
            ...navigationData.profileItem
          })]
        })]
      })
    })]
  });
};
export { NavigationMobile, onMobileNavigationToggle, toggleMobileNavigation };
