const ignoreNodeNames = ["SCRIPT", "STYLE"];
let changedNodes = [];
const getElementSiblings = domNode => {
  const siblings = [];
  let currentNext = domNode.nextElementSibling;
  while (currentNext) {
    if (!ignoreNodeNames.includes(currentNext.nodeName)) {
      siblings.push(currentNext);
    }
    currentNext = currentNext.nextElementSibling;
  }
  let currentPrev = domNode.previousElementSibling;
  while (currentPrev) {
    if (!ignoreNodeNames.includes(currentPrev.nodeName)) {
      siblings.push(currentPrev);
    }
    currentPrev = currentPrev.previousElementSibling;
  }
  return siblings;
};
/**
 * Updates all silbing elements of a given node within the body
 * to have aria-hidden set to true
 * @param domNode {HTMLElement}
 * @param initialCall {Boolean}
 */
const createAriaHider = (domNode, initialCall = false) => {
  var _a;
  if (initialCall) {
    changedNodes = [];
  }
  getElementSiblings(domNode).forEach(node => {
    const attr = node.getAttribute("aria-hidden");
    if (attr === "true") {
      return;
    }
    node.setAttribute("aria-hidden", "true");
    changedNodes.push({
      node,
      attr
    });
  });
  if (((_a = domNode.parentNode) === null || _a === void 0 ? void 0 : _a.nodeName) != "BODY") {
    createAriaHider(domNode.parentNode);
  }
  return () => {
    changedNodes.forEach(changedNode => {
      if (typeof changedNode.attr != "string") {
        changedNode.node.removeAttribute("aria-hidden");
      } else {
        changedNode.node.setAttribute("aria-hidden", changedNode.attr);
      }
    });
  };
};
export { createAriaHider as default };
