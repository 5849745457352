import { LoadingHeading, LoadingParagraph } from "@kaizen/components"
import { TileContainer } from "../TileBlock"
import Spacer from "../Spacer/Spacer"

export const TileLoadingSmall = () => {
  return (
    <TileContainer>
      <LoadingHeading variant="heading-3" width={60} />
      <Spacer gap={1} />
      <LoadingParagraph width={100} />
      <LoadingParagraph width={80} />
    </TileContainer>
  )
}
